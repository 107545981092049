body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container for core values */
.core-values-container {
  padding: 20px;
  background-color: #f9f9f9;
  margin-left: 60px;
  margin-right: 50px;;
}

/* Header for the core values section */
.core-values-header {
  text-align: center;
  margin-bottom: 30px;
}

/* Main heading */
.core-values-heading {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
}

/* Introductory comment */
.core-values-intro {
  font-size: 1.2em;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Container for the list of values */
.values-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Each value item */
.value-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

/* Hover effect for value items */
.value-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Icon styling */
.value-icon {
  margin-right: 15px;
  margin-top: 17px;
  font-size: 2em;
  color: #007bff; /* Adjust color as needed */
}

/* Title of each value */
.value-title {
  font-size: 1.3em;
  margin-bottom: 5px;
  color: #333;
}

/* Description of each value */
.value-description {
  font-size: 1.1em;
  color: #555;
  left: 30px;
}
