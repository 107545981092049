.body {
    margin: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh; 
  }
  
  
  .about-section {
    flex: 1;
    text-align: left;
    padding-right: 50px;
    max-width: 50%; 
    margin-left: 80px;
    margin-top: 60px;
  }
  

  .about-title {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  
  .about-paragraph {
    margin-bottom: 15px;
  }
  
 
  .images-section {
    flex: 1;
    position: relative; 
    max-width: 50%;
  }
  

  .about-image {
    margin-top: 60px;
    position: absolute; 
    width: 80%; 
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s ease; 
  }
  
  
  .image1 {
    top: 0; 
    left: 0; 
    z-index: 1; /* Layering order */
  }
  
  .image2 {
    top: 220px; 
    left: 100px;
    z-index: 2; 
  }
  

  .about-image:hover {
    transform: scale(1.05); 
  }
  