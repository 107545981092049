body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Main container for services */
  .services-container {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-left: 60px;
    margin-right: 50px;
  }
  
  /* Header for the services section */
  .services-header {
    grid-column: 1 / -1; /* Span the header across all columns */
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Main heading */
  .services-heading {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Introductory comment */
  .services-intro {
    font-size: 1.2em;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Each service box */
  .service-box {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  /* Hover effect for service boxes */
  .service-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Title of each service */
  .service-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  /* Icon styling */
  .service-icon {
    margin-right: 10px;
    font-size: 1.2em;
    color: #007bff; /* Adjust color as needed */
  }
  
  /* Description and lists */
  .service-description,
  .service-list {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #555;
  }
  
  .service-list li {
    margin-bottom: 5px;
  }
  